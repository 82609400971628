import React from 'react';
import {
  Flex,
  Heading,
  Text,
  Textarea,
  Input,
  Button,
  Label,
} from '@theme-ui/components';

import ContactWidget from '../../../../../../gatsby-theme-minimal/src/components/General/ContactDetails';
// import cloudinaryImageOptimizer from '../../../helpers/cloudinaryImageOptimizer'
import backgroundStylesGradient from '../../../../../../gatsby-theme-minimal/src/helpers/backgroundStylesGradient';

export default function ContactForm({
  businessData,
  configData,
  options,
  variantName,
  poweredImages,
  locationIndex,
  isMultiLocationSite,
}) {
  const { name, street, city, state, zip, phone } = businessData;

  const {
    containerStyles,
    title,
    subtitle,
    text,
    contactDetails,
    email,
    backgroundImages,
    backgroundGradient,
  } = options;

  // const [cloudinaryImage, setCloudinaryImage] = useState(undefined)

  const formName = `${name}-${city}`;

  const styles = containerStyles ? JSON.parse(containerStyles) : {};
  const variant = variantName ? variantName : 'contactFormV1';

  const backgroundStyles = backgroundImages
    ? backgroundStylesGradient(
        backgroundImages[locationIndex],
        backgroundGradient,
        poweredImages,
        locationIndex
      )
    : '';

  return (
    <Flex
      id="form"
      variant={`${variant}.container`}
      sx={{
        // background: backgroundImages
        //   ? `linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.5) ), url('${
        //       cloudinaryImage ? cloudinaryImage : ''
        //     }')`
        //   : '',
        backgroundImage: backgroundStyles,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        label: {
          padding: '0.25rem 0.75rem 0.25rem',
          marginTop: '1rem',
          marginBottom: '0.5rem',
          // borderBottom: '1px solid',
          backgroundColor: '#c8a85e2b',
          borderRadius: '100px',
          width: 'fit-content',
        },
        ...styles,
      }}
    >
      {title && (
        <Heading as="h2" variant={`${variant}.title`} className="title">
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading as="h3" variant={`${variant}.subtitle`} className="subtitle">
          {subtitle}
        </Heading>
      )}
      {text && (
        <Text as="p" variant={`${variant}.text`} className="text">
          {text}
        </Text>
      )}

      {contactDetails && (
        <>
          <ContactWidget
            phone={phone}
            address={{ name, street, city, state, zip }}
            email={email}
            alternativeGoogleMapLink={configData.alternativeGoogleMapLink}
          />
          <br></br>
        </>
      )}

      <Flex
        as="form"
        variant={`${variantName}.formContainer`}
        name={formName ? formName : 'contactform'} //  this can not be dyanmic form names and details must be static      method='POST'
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <Input
          type="hidden"
          name="form-name"
          value={formName ? formName : 'contactform'}
        />
        <Label htmlFor="name">Name</Label>
        <Input
          variant={`${variantName}.name`}
          name="name"
          type="text"
          id="name"
          mb={3}
          placeholder="Enter Your Full Name"
          className="inputField name"
          required
        />
        <Label htmlFor="email">Email Address</Label>
        <Input
          variant={`${variantName}.email`}
          type="email"
          name="email"
          id="email"
          mb={3}
          placeholder="Your Email Address"
          className="inputField email"
          required
        />
        <Label htmlFor="phone">Contact Number</Label>
        <Input
          variant={`${variantName}.email`}
          sx={{
            '&:invalid': {
              borderColor: 'red',
              border: '2px solid',
            },
          }}
          type="tel"
          name="phone"
          id="phone"
          mb={3}
          pattern="[0-9]+"
          placeholder="Enter Your Phone Number"
          className="inputField email"
          required={false}
        />
        <Label htmlFor="typeofevent">Type Of Event</Label>
        <Input
          variant={`${variantName}.name`}
          name="typeofevent"
          type="text"
          id="typeofevent"
          mb={3}
          placeholder="Your Event Type"
          className="inputField name"
          required
        />
        <Label htmlFor="eventsize">Size Of Event (Number of People)</Label>
        <Input
          variant={`${variantName}.name`}
          name="eventsize"
          type="number"
          id="eventsize"
          mb={3}
          placeholder="50?"
          className="inputField name"
          required
        />
        <Label htmlFor="eventdate">Prefered Date</Label>
        <Input
          variant={`${variantName}.email`}
          type="date"
          name="eventdate"
          id="eventdate"
          mb={3}
          placeholder="Prefered Date"
          className="inputField email"
          required
        />

        <Label htmlFor="message">Message</Label>
        <Textarea
          name="message"
          id="message"
          variant={`${variantName}.message`}
          rows="6"
          mb={3}
          placeholder="Your Message"
          className="textField message"
          required
        />
        {isMultiLocationSite ? (
          <Input
            sx={{ display: 'none !important' }}
            name="location"
            id="location"
            value={city}
            placeholder={city}
          />
        ) : (
          ''
        )}
        <Button className="button submitBtn" type="submit">
          Submit
        </Button>
      </Flex>
    </Flex>
  );
}
